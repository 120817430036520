import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Accordion } from "react-bootstrap";

import { FaFilePdf, FaRegFileWord, FaCheck, FaDotCircle } from "react-icons/fa";
import img1 from "../../img/career/karosszeria_01_scaled.jpg";
import img2 from "../../img/career/karosszeria_02_scaled.jpg";

import "./style.css";

const Career = () => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
  };

  return (
    <section className="gauto-service-details-area section_70">
      <Container>
        <Row>
          {<Col lg={4}>
            <div className="service-details-left">
              <div className="sidebar-widget">
                <ul className="service-menu">
                  <li  className="active">
                    <Link to="/karosszerialakatos">Karosszérialakatos</Link>
                  </li>
                  {/* <li>
                    <Link to="/szerelo">Szerelő</Link>
                  </li> */}
                </ul>

              </div>
            </div>
          </Col>}
          <Col lg={8}>
            <div className="service-details-right">
              <h3>Karosszérialakatos</h3>
              <p>
                Autószerelő, lakatos vagy géplakatoszakmával rendelkező munkatársat keresünk alvázhuzatópad mellé.
              </p>
              <p>
                Feltételek:
              </p>
              <p>
                <FaDotCircle className="career-list-icon"/>&nbsp;Bűntetlen előélet
              </p>
              <p>
                <FaDotCircle className="career-list-icon"/>&nbsp;Személyes megbeszélés alapján
              </p>
              <p>
                <FaDotCircle className="career-list-icon"/>&nbsp;Telefonon érdeklődni a (30) 953 4803-as számon
              </p>
              <Row>
                <Col md={6} className="pad-right-sm">
                  <div className="service-details-image">
                    <img src={img1} alt="service details" />
                  </div>
                </Col>
                <Col md={6} className="pad-left-sm">
                  <div className="service-details-image">
                    <img src={img2} alt="service details" />
                  </div>
                </Col>
              </Row>
              <div className="service-details-list clearfix">
                <ul>
                  <li>
                    <FaCheck /> Ut ad minim veniam quis nostrud
                  </li>
                  <li>
                    <FaCheck /> Labore et dolore magna aliqua
                  </li>
                  <li>
                    <FaCheck /> Lorem ipsum dolor sit amet consectetur adipisicing
                  </li>
                  <li>
                    <FaCheck /> Ut ad minim veniam quis nostrud
                  </li>
                  <li>
                    <FaCheck /> Andomised words which don't look{" "}
                  </li>
                </ul>
                <ul>
                  <li>
                    <FaCheck /> Labore et dolore magna aliqua
                  </li>
                  <li>
                    <FaCheck /> Sed do eiusmod tempor incididunt
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Career;
